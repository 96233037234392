<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <!-- View Data Table -->

      <v-card class="card-shadow border-radius-xl mt-6">
        <v-card-text class="px-0 py-0">
          <v-row class="ml-2">
            <v-col cols="12" md="4">
              <v-menu
                ref="mnu_frm_date"
                v-model="mnu_frm_date"
                :close-on-content-click="false"
                :return-value.sync="mnu_frm_date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="frm_date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    placeholder="Date"
                    persistent-hint
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="green lighten-1"
                  header-color="primary"
                  v-model="frm_date"
                  no-title
                  scrollable
                  :max="to_date"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="mnu_frm_date = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.mnu_frm_date.save(frm_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu></v-col
            >
            <v-col cols="12" md="4">
              <v-menu
                ref="mnu_to_date"
                v-model="mnu_to_date"
                :close-on-content-click="false"
                :return-value.sync="mnu_to_date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="to_date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    placeholder="Date"
                    persistent-hint
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="green lighten-1"
                  header-color="primary"
                  v-model="to_date"
                  no-title
                  scrollable
                  :min="frm_date"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="mnu_to_date = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.mnu_to_date.save(to_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4" align-self="center" class="mt-5">
              <v-btn
                color="primary"
                elevation="0"
                :ripple="false"
                height="43"
                dark
                class="text-capitalize btn-ls btn-primary bg-success py-3 px-6"
                @click="search"
              >
                Search
              </v-btn></v-col
            >
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="card-shadow border-radius-xl mt-6">
        <v-card-text class="px-0 py-0">
          <v-data-table
            :headers="headers"
            :items="tableData"
            mobile-breakpoint="0"
            fixed-header
            class="table"
            :footer-props="{
              'items-per-page-options': [100, 200, 500, -1],
            }"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <!-- <v-toolbar-title>IRMC</v-toolbar-title> -->
                <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
                <v-spacer></v-spacer>
                <!--    -->
                <!--   -->
                <v-btn
                  v-show="currentPage.permissions.includes('CREATE')"
                  color="primary"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                  "
                  @click="gotoCreate"
                >
                  New IRMC
                </v-btn>
              </v-toolbar>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                @click="viewItem(item)"
                icon
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"
                class=""
                color="green"
                >Details
              </v-btn>

              <!-- <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-show="currentPage.actions_allowed"
                    v-bind="attrs"
                    v-on="on"
                    icon
                    elevation="0"
                    :ripple="false"
                    height="28"
                    min-width="36"
                    width="36"
                    class="ml-5 mx-1"
                  >
                    Actions
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(itm, i) in currentPage.actions_allowed"
                    :key="i"
                  >
                    <v-btn
                      icon
                      elevation="0"
                      :ripple="false"
                      height="28"
                      min-width="36"
                      width="80px"
                      class="my-1"
                      @click="allowedAction(itm, item.id)"
                    >
                      {{ itm }}
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu> -->
            </template>
            <template v-slot:[`item.incoming_date`]="{ item }">
              <span>
                {{ formatDate(item.incoming_date) }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import api from "../api";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      ref: "",
      supplier_name: "",
      po_number: [],
      bill_no: "",
      vehicle_no: "",
      editedId: -1,
      bags: 0,
      overlay: false,
      boolTrue: true,
      ans: [],
      all_questions: [],
      questions: [],

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_date: false,

      frm_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_frm_date: false,

      to_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_to_date: false,

      dialog: false,
      tableData: [],
      headers: [
        {
          text: "Incoming Date",
          value: "incoming_date",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 120,
          sortable: false,
        },
        {
          text: "Name Of Supplier",
          value: "name_of_supplier",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 120,
          sortable: false,
        },
        // {
        //   text: "PO Number",
        //   value: "po_number",
        //   class: "text-secondary font-weight-bolder opacity-10",
        //   align: "left",
        //   width: 200,
        //   sortable: false,
        // },
        // {
        //   text: "Bill No",
        //   value: "bill_no",
        //   class: "text-secondary font-weight-bolder opacity-10",
        //   align: "left",
        //   width: 200,
        //   sortable: false,
        // },
        {
          text: "Batch No",
          value: "vehicle_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 120,
          sortable: false,
        },
        {
          text: "NO: of Bags",
          value: "number_of_bags",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 120,
          sortable: false,
        },
        {
          text: "REF",
          value: "ref",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 120,
          sortable: false,
        },
        {
          text: "status",
          value: "status",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 120,
          sortable: false,
        },
        {
          value: "actions",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 120,
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.overlay = true;
      this.tableData = await api.getData();
      // this.all_questions = await api.getQuestion();
      this.overlay = false;
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    async search() {
      this.tableData = await api.search(this.frm_date, this.to_date);
    },
    async viewItem(item) {
      this.overlay = true;
      this.$router.push({
        name: "IRMC-View",
        params: { id: item.id },
        query: {
          currentPage: this.currentPage,
        },
      });
    },

    async allowedAction(item, id) {
      this.overlay = true;
      try {
        api.allowedAction({
          id: id,
          action: item,
        });

        this.overlay = false;
        this.showSuccessAlert("State updated");
      } catch (error) {
        this.showErrorAlert(error);
      } finally {
        this.initialize();
      }
    },

    cancel() {
      this.editedId = -1;
      this.questions = [];
      this.ans = [];
      this.dialog = false;
      this.initialize();
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        showConfirmButton: true,
        type: "error",

        icon: "error",
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    gotoCreate() {
      this.$router.push({ name: "PreCreateIrmc" });
    },
  },
  computed: {
    ...mapGetters({
      currentPage: "getCurrentPage",
    }),
  },
};
</script>

<style></style>
